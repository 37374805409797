import anime from 'animejs';
import { graphql, Link, navigate } from 'gatsby';
import React from 'react';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import Head from '../components/Head';

const IndexPage: React.VFC<{
  data: any;
  location: Location;
}> = ({ data, location }) => {
  useEffect(() => {}, []);

  return (
    <>
      <Head title="" />
    </>
  );
};

export default IndexPage;

// export const pageQuery = graphql`
//   query {
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//         }
//         frontmatter {
//           date
//           title
//           description
//           hidden
//           thumbnail {
//             publicURL
//           }
//         }
//       }
//     }
//   }
// `;
